<script setup lang="ts"></script>

<template>
    <svg
        width="30"
        height="30"
        viewBox="0 0 30 30"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
    >
        <path
            d="M28.8307 6.25575C27.7478 4.96846 25.7484 4.44336 21.9301 4.44336H8.06961C4.1639 4.44336 2.13068 5.00233 1.05184 6.37284C0 7.7091 0 9.67797 0 12.4029V17.5967C0 22.8758 1.248 25.5562 8.06961 25.5562H21.9302C25.2414 25.5562 27.0763 25.0928 28.2633 23.9568C29.4806 22.7918 30 20.8897 30 17.5967V12.4029C30 9.52923 29.9186 7.54875 28.8307 6.25575ZM19.2601 15.7169L12.9661 19.0063C12.8254 19.0799 12.6715 19.1164 12.5178 19.1164C12.3438 19.1164 12.1702 19.0695 12.0165 18.9764C11.7269 18.801 11.5501 18.4871 11.5501 18.1486V11.5908C11.5501 11.2529 11.7264 10.9393 12.0154 10.7638C12.3045 10.5882 12.664 10.5762 12.9639 10.732L19.2579 14.0003C19.5781 14.1666 19.7792 14.4971 19.7797 14.8578C19.7801 15.2188 19.5798 15.5498 19.2601 15.7169Z"
            fill="url(#paint0_linear_1525_13650)"
        />
        <defs>
            <linearGradient
                id="paint0_linear_1525_13650"
                x1="15"
                y1="4.44336"
                x2="15"
                y2="25.5562"
                gradientUnits="userSpaceOnUse"
            >
                <stop stop-color="#A2BADC" />
                <stop offset="1" stop-color="#68799C" />
            </linearGradient>
        </defs>
    </svg>
</template>
